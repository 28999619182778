import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import moment from "moment";

import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

import { ImagesService } from "../../services/helper/images.service";
import { PostsService } from "../../services/posts/posts.service";
import { ProjectService } from "../../services/project/project.service";

import { environment } from "environments/environment";
import mime from "mime";
import { Comment, Like } from "../../shared/models/comun.interface";
import { Post } from "../../shared/models/posts.interface";
import { User } from "../../shared/models/user.interface";

interface ImageObject {
	image?: string;
	thumbImage?: string;
	alt?: string;
	type: "img" | "file";
	title: string;
	id: any;
	fileName: any;
}

@Component({
	selector: "item-post-pro",
	templateUrl: "./item-post-pro.component.html",
	styleUrls: ["./item-post-pro.component.scss"],
})
export class ItemPostProComponent implements OnInit {
	@Input("id") idPost: number;
	@Input("datos") post: Post;
	@Input("user") user: User;
	@Input("fromInside") fromInside: boolean = false;
	@Input("canEdit") canEdit: any;
	canEditComments;
	@Input("canSee") canSee: any;

	canSeeComments;

	htmlContent: SafeHtml;
	form: UntypedFormGroup;
	showFormComent: boolean;

	projectId: number;
	likes: Array<Like>;
	tooltipLikes: string;
	comments: Array<Comment>;
	tit: any;
	isRankingLink = false;

	contenidoFiltrado = "";

	imageObject: ImageObject[] = [
		// {
		// 	image: "https://fotografiaesencial.com/blog/wp-content/uploads//2010/05/Orientaci_C3_B3n-Vertical-1_c2ad35dc8c2d4c419ad12739d474f63b.jpg",
		// 	thumbImage: "https://fotografiaesencial.com/blog/wp-content/uploads//2010/05/Orientaci_C3_B3n-Vertical-1_c2ad35dc8c2d4c419ad12739d474f63b.jpg",
		// 	alt: "alt of image",
		// 	title: "",
		// 	type: "img",
		// },
		// {
		// 	image: "https://cdn.sanity.io/images/x6mh5oiy/production/ace36b14d80d416574f6724050596da0d62766c9-320x320.jpg",
		// 	thumbImage: "https://cdn.sanity.io/images/x6mh5oiy/production/ace36b14d80d416574f6724050596da0d62766c9-320x320.jpg",
		// 	alt: "alt of image",
		// 	title: "",
		// 	type: "img",
		// },
		// {
		// 	title: "Prevencion de riesgos laborales",
		// 	type: "file",
		// },
		// {
		// 	title: "Prueba de nombre ",
		// 	type: "file",
		// },
	];

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private sanitizer: DomSanitizer,
		private snackBar: MatSnackBar,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private postsSvc: PostsService,
		public dialog: MatDialog
	) {
		this.comments = [];
		this.likes = [];
		this.tooltipLikes = "";
	}

	ngOnInit(): void {
		if (this.post?.contenido) {
			if (this.post?.imagenesCarrusel === 1) {
				this.processContent(this.post.contenido);
			} else {
				this.contenidoFiltrado = this.post.contenido;
			}
			this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.contenidoFiltrado);
		}

		if (this.post?.documents) {
			this.processDocuments(this.post.documents);
		}
		this.canEditComments = this.canEdit;
		this.canSeeComments = this.canSee;
		this.form = this._formBuilder.group({
			comentario: [""],
		});

		this.showFormComent = false;

		this.projectId = parseInt(this.projectSvc.getProjectId());
		moment.locale(this.user.language);
		this.loadLikesComments();
		this.traducirTitulo();
	}

	procesarContenidoParaVisualizacion(contenido: string): string {
		if (!contenido) return "";
		return contenido;
	}

	getImagePerson(nickname: string) {
		return this.imagesSvc.getImageThumb(nickname);
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const postDate = moment(timestamp);
		const diffDias = hoy.diff(postDate, "days");

		let momentDate = postDate.fromNow();
		if (diffDias > 21) {
			momentDate = postDate.format("DD MMMM YYYY");
		}

		return momentDate;
	}
	momentDateFormat(timestamp: number) {
		return moment(timestamp).format("DD/MM/YYYY HH:mm");
	}

	toogleLikePost() {
		this.postsSvc.toogleLike(this.post.id).subscribe(
			(data) => {
				if (this.post.isLiked) {
					this.post.numLikes--;
					this.post.isLiked = false;
				} else {
					this.post.numLikes++;
					this.post.isLiked = true;
				}
				this.loadLikes();
			},
			(error) => {
				this.snackBar.open(error, "X", {
					duration: 3000,
				});
			}
		);
	}

	toogleFormComment(idPost: number) {
		if (this.showFormComent) {
			this.showFormComent = false;
		} else {
			this.showFormComent = true;
		}
	}

	loadLikesComments(): void {
		if (this.post.numComments > 0) {
			this.loadComments();
		}
		if (this.post.numLikes > 0) {
			this.loadLikes();
		}
	}

	loadLikes(): void {
		this.postsSvc.postLikes(this.post.id).subscribe((data: any) => {
			this.likes = data.elements;
			this.tooltipLikes = "";
			for (const like of this.likes) {
				if (this.tooltipLikes !== "") {
					this.tooltipLikes += "\n";
				}
				this.tooltipLikes += like.autor.name;
			}
		});
	}

	loadComments(): void {
		this.postsSvc.postComments(this.post.id).subscribe((data: any) => {
			this.comments = data.elements;
		});
	}

	traducirTitulo() {
		if (this.post?.["title_prefix"]?.includes("${ranking.month}")) {
			this.isRankingLink = true;
			var expresion = /\/ranking\/+([0-9])+\/+([0-9])?([0-9])/.exec(this.post["title_prefix"]);
			var year = expresion[0].split("/")[2];
			var month = this.convertirMes(expresion[0].split("/")[3]);
			this.tit = this.post["title_prefix"].replace("${ranking.month}", "Ranking de " + year + " " + month);
		} else {
			this.tit = this.post?.titulo || "";
		}
		return this.tit;
	}

	convertirMes(numeroMes) {
		var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

		if (numeroMes >= 1 && numeroMes <= 12) {
			return meses[numeroMes - 1];
		}
	}
	processContent(content: string): void {
		const base64Regex = /data:image\/[a-zA-Z]+;base64,[^"]+/g;
		const matches = content.match(base64Regex);
		if (matches) {
			matches.forEach((base64Image, index) => {
				this.imageObject.push({
					image: base64Image,
					thumbImage: base64Image,
					alt: `Image ${index + 1}`,
					title: "",
					type: "img",
					id: 0,
					fileName: "",
				});
				content = content.replace(base64Image, "");
			});
		}
		this.contenidoFiltrado = content.trim();
	}

	blobToBase64(blob) {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(blob);
		});
	}

	processDocuments(documents) {
		for (const document of documents) {
			const mimeType = mime.getType(document.fileName) || "application/octet-stream";
			const data: ImageObject = {
				title: document.fileName,
				type: document.image ? "img" : "file",
				id: document.id,
				fileName: document.fileName,
			};

			if (data.type === "img") {
				data["alt"] = document.fileName;
				data["image"] = environment.urlDomainImages + document.url;
				data["thumbImage"] = environment.urlDomainImages + document.url;
				this.imageObject.push(data);
			} else this.imageObject.push(data);
		}
	}
}
