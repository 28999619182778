<ng-container>
	<h2 mat-dialog-title class="m-0 mb-8">Incidencia</h2>

	<div style="width: 60vh" *ngIf="inc" class="p-12">
		<div fxLayout="row " class="p-4">
			<div fxLayout="column" fxLayoutAlign="center center" class="p-2 imagen-cont" *ngIf="inc.username">
				<div class="person-avatar avatar-perfil">
					<img class="img-circle" [src]="getImageUser(inc.username)" />
				</div>
			</div>
			<div fxLayout="column" fxLayoutAlign="center center" class="p-2">
				<h5 *ngIf="inc.displayName" style="font-size: 18px" class="mt-4 mb-4">{{ inc.displayName }}</h5>
			</div>
		</div>
		<div fxLayout="row " class="p-3" fxLayoutAlign="center center">
			<div fxLayout="column" fxLayoutAlign="center center">
				<b *ngIf="inc.tipo == 0" class="icon-badgeM" style="font-size: 22px">Baja médica</b>
				<b *ngIf="inc.tipo == 3" class="icon-badgeM" style="font-size: 22px">Asistencia a médico</b>

				<b *ngIf="inc.tipo == 1" class="icon-badgeO" style="font-size: 22px">Otra</b>
				<b *ngIf="inc.tipo == 4" class="icon-badgeO" style="font-size: 22px">Asistencia a juicio</b>
				<b *ngIf="inc.tipo == 5" class="icon-badgeO" style="font-size: 22px">Asistencia examen oficial</b>
				<b *ngIf="inc.tipo == 6" class="icon-badgeO" style="font-size: 22px">Maternidad/Paternidad</b>
			</div>
		</div>

		<div fxLayout="row " fxLayoutAlign="start start" class="p-8">
			<div fxLayout="column" fxLayoutAlign="start start" fxFlex="2 1 0" class="p-4 w-100-p">
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<span style="font-size: 16px">Motivo</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<div
						fxLayout="column"
						fxLayoutAlign="start start"
						style="background-color: rgb(217, 217, 217); border: 1px solid gray; padding: 8px; border-radius: 5px; width: 100%"
					>
						<span style="font-style: italic; width: 100%">{{ inc?.motivo ?? "No hay motivo registrado" }}</span>
					</div>
				</div>
			</div>
		</div>

		<div fxLayout="row " fxLayoutAlign="start start" class="p-8">
			<div fxLayout="column" fxLayoutAlign="start start" fxFlex="2 1 0" class="p-4 w-100-p">
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<span style="font-size: 16px">Fecha de inicio</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<div
						fxLayout="column"
						fxLayoutAlign="start start"
						style="background-color: rgb(217, 217, 217); border: 1px solid gray; padding: 8px; border-radius: 5px; width: 100%"
					>
						<span style="font-style: italic; width: 100%">{{ inc.fecha_ini }}</span>
					</div>
				</div>
			</div>

			<div fxLayout="column" fxLayoutAlign="start start" fxFlex="2 1 0" class="p-4 w-100-p">
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<span style="font-size: 16px">Fecha de fin</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<div
						fxLayout="column"
						fxLayoutAlign="start start"
						style="background-color: rgb(217, 217, 217); border: 1px solid gray; padding: 8px; border-radius: 5px; width: 100%"
					>
						<span style="font-style: italic; width: 100%">{{ inc.fecha_fin }}</span>
					</div>
				</div>
			</div>

			<div fxLayout="column" fxLayoutAlign="start start" fxFlex="1 1 0" class="p-4 w-100-p"></div>
			<div fxLayout="column" fxLayoutAlign="start start" fxFlex="1 1 0" class="p-4 w-100-p">
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					<span style="font-size: 16px">Justificante</span>
				</div>
				<div fxLayout="row" fxLayoutAlign="start start" class="w-100-p">
					@if (inc.archivo != "") {
						<span style="font-size: 16px; padding: 8px">✅</span>
					} @else {
						<span style="font-size: 16px; padding: 8px">❌</span>
					}
				</div>
			</div>
		</div>
	</div>
	<div style="width: 60vh" *ngIf="!inc" class="p-12">
		<div fxLayout="row " class="p-4">
			<div fxLayout="column" fxLayoutAlign="center center" class="p-2 imagen-cont">
				<span style="font-style: italic; width: 100%">No se ha podido encontrar esta incidencia</span>
			</div>
		</div>
	</div>
</ng-container>
