import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { FuseConfirmDialogComponent } from "@fuse/components/confirm-dialog/confirm-dialog.component";
import { TranslateService } from "@ngx-translate/core";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";
import { UserService } from "app/services/user/user.service";
import { Persona, User } from "app/shared/models/user.interface";

import moment from "moment";
import "moment/locale/es";
moment.locale("es");

@Component({
	selector: "action-plan",
	templateUrl: "./action-plan.component.html",
	styleUrls: ["./action-plan.component.scss"],
})
export class ActionPlanComponent implements OnInit {
	@Input("datos-plan-accion") datos: any;
	@Input("revisionPeriodEnabled") revisionPeriodEnabled: any;
	@Input("user-result") userResult: any;
	@Input("evpartial-id") evalPartialId: number;
	@Input("period-id") evalPeriodId: number;
	@Input("user") user: User;
	@Input("person") person: Persona;
	@Input("editable") editable: boolean; // Se puede modificar el Plan de acción
	@Input("canBeBlocked") canBeBlocked: boolean; // Se puede marcar como bloqueado (fixed) el plan de acción

	@Input("read-only") readOnly: boolean; // Se puede modificar el valor
	@Input("startPeriod") startPeriod: string;
	@Input("inEvaluation") inEvaluation: boolean;
	@Input("fromEv") fromEv: boolean;
	@Input("permisos") permisos: any;

	@Input("canComment") canComment: any;

	@Output() onChangeValue = new EventEmitter();
	@Output() reload = new EventEmitter();

	formComments: UntypedFormGroup;
	showFormComent: boolean;
	listComments: any;

	formActionPlans: UntypedFormGroup;
	editMode: boolean;
	showHistory: boolean;
	listHistory: any;

	projectName: string;

	constructor(
		private _formBuilder: UntypedFormBuilder,
		private imageSvc: ImagesService,
		private evaluationSvc: EvaluationService,
		private dialog: MatDialog,
		private userSvc: UserService,
		private projectSvc: ProjectService,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		if (!this.permisos) {
			this.permisos = { w: 1, r: 1, d: 1 };
		}
		if (this.fromEv) {
			this.datos.noApply = 0;
		}
		this.formComments = this._formBuilder.group({
			comentario: [""],
		});
		this.formActionPlans = this._formBuilder.group({
			id: this.datos.id,
			title: this.datos.title,
			description: this.datos.description,
			startDate: moment(this.datos.startDate).toDate(),
			endDate: moment(this.datos.endDate).toDate(),
		});

		this.editMode = this.datos.isNew ? true : false;
		this.showFormComent = false;

		// Si el PDA se carga desde una evaluación
		if (this.inEvaluation) {
			this.evaluationSvc.getEvaluationActionPlanResults(this.datos.id, this.evalPeriodId).subscribe((evResult) => {
				// Si la evaluación está cerrada, cargar solo los resultados en esa evaluación (evaluation_result)
				if (this.readOnly) {
					this.processData(evResult, null, false);
				}
				// Si la evaluación aun está abierta, cargar los resultados de la evaluacion y los provisionales.
				else {
					this.evaluationSvc.getActionPlanResults(this.datos.id).subscribe((apResult) => {
						this.processData(evResult, apResult, true);
					});
				}
			});
		}
		// Si el PDA se carga desde el SIED, cargar los resultados provisionales (action_plan_result)
		else {
			this.evaluationSvc.getActionPlanResults(this.datos.id).subscribe((apResult) => {
				this.processData(apResult, null, false);
			});
		}

		moment.locale(this.userSvc.getLanguageUser());

		// En Exxita, solo se pueden editar los planes de acción creados después del periodo actual.
		this.projectSvc.getProjectConfig().subscribe((project) => {
			this.projectName = project.name;
			if (project.id == 9) {
				const _startPeriod = moment(this.startPeriod);
				const _creationDate = moment(this.datos.creationDate);
				if (_creationDate > _startPeriod) {
					this.editable = true;
				}
			}
		});
	}

	processData(data1, data2, putInEvaluation: boolean) {
		// Resultado del propio usuario
		if (!this.userResult) {
			const data1ApResult = data1.find((apr) => apr.evaluatorId === this.user.id);
			const data2ApResult = data2 ? data2.find((apr) => apr.evaluatorId === this.user.id) : null;
			this.userResult = data1ApResult ? data1ApResult.result : data2ApResult ? data2ApResult.result : null;
			// Meter el resultado de action_plan_result en evaluation_result
			if (putInEvaluation) {
				this.setResult(this.userResult);
			}
		}
		// Resultado de los otros evaluadores
		const data1EvaluatorsApResults = data1.filter((apr) => apr.evaluatorId !== this.user.id);
		const data2EvaluatorsApResults = data2 ? data2.filter((apr) => apr.evaluatorId !== this.user.id) : null;
		if (data1EvaluatorsApResults) {
			this.datos.evaluators = data1EvaluatorsApResults;
		} else {
			this.datos.evaluators = data2EvaluatorsApResults;
		}
	}

	toogleFormComment() {
		this.showFormComent = !this.showFormComent;
		if (this.showFormComent) {
			this.loadComments();
		}
	}

	loadComments() {
		this.evaluationSvc.getActionPlanComments(this.datos.id).subscribe((data) => {
			this.listComments = data;
			console.log("PA Comments", data);
		});
	}

	showRevisions() {
		this.showHistory = !this.showHistory;
		if (this.showHistory) {
			this.evaluationSvc.getActionPlanRevision(this.datos.id).subscribe((data) => {
				this.listHistory = data;
				console.log("PA Revision", data);
			});
		}
	}

	toogleEdit() {
		this.editMode = !this.editMode;
		console.log(this.formActionPlans.value["startDate"]);
		console.log(this.formActionPlans.value["endDate"]);
	}

	editPA() {
		this.evaluationSvc.saveActionPlan(this.formActionPlans.value).subscribe((data) => {
			if (data.success) {
				this.reload.emit();
			}
		});
		this.toogleEdit();
	}

	removePA() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: [],
			//width: '25vw',
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "¿Seguro quiere eliminar el plan de acción?",
				confirmMessage: "Se eliminará el plan de acción de manera definitiva.",
				showCancelButton: true,
			},
		});

		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.evaluationSvc.deleteActionPlan(this.formActionPlans.value.id).subscribe((data) => {
					if (data.success) {
						this.reload.emit();
					}
				});
			}
		});
	}

	setResult(result) {
		this.userResult = result;
		this.onChangeValue.emit({
			id: this.datos.id,
			target: "actionId",
			value: result,
		});
	}

	getImagePerson(nickname: string) {
		return this.imageSvc.getImageThumb(nickname);
	}

	momentDate(timestamp: number) {
		const hoy = moment();
		const badgeDate = moment(timestamp);
		const diffDias = hoy.diff(badgeDate, "days");

		let momentDate = badgeDate.fromNow();
		if (diffDias > 21) {
			momentDate = badgeDate.format("DD MMMM YYYY hh:mm");
		}

		return momentDate;
	}

	formatDate(timestamp: number) {
		if (!timestamp) {
			return "";
		} else {
			return moment(timestamp).format("DD/MM/YYYY");
		}
	}

	addComment() {
		if (!this.formComments.value.comentario.trim()) {
			alert("El comentario no puede estar vacio");
			return;
		}
		const _comment = {
			action_plan_id: this.datos.id,
			evaluation_partial_id: this.evalPartialId,
			content: this.formComments.value.comentario,
		};
		this.evaluationSvc.saveActionPlanComments(_comment).subscribe((data) => {
			if (data.success) {
				this.formComments.reset();
				this.loadComments();
				this.datos.comments = parseInt(this.datos.comments) + 1;
			}
		});
	}

	toogleEditComment(comment) {
		const selectedcomment = this.listComments.find((c) => c.id === comment.id);
		selectedcomment.editable = !selectedcomment.editable;
	}

	editComment(comment, newContent) {
		comment.content = newContent;
		comment.enabled = true;
		this.evaluationSvc.saveActionPlanComments(comment).subscribe((data) => {
			if (data.success) {
				this.loadComments();
			}
		});
	}

	removeComment(comment) {
		comment.enabled = false;
		this.evaluationSvc.saveActionPlanComments(comment).subscribe((data) => {
			if (data.success) {
				this.loadComments();
				this.datos.comments = parseInt(this.datos.comments) - 1;
			}
		});
	}

	revisePA() {
		this.evaluationSvc.reviseActionPlan({ actionPlanId: this.datos.id }).subscribe((data) => {
			if (data.success) {
				this.datos.isRevised = true;
			}
		});
	}

	lockActionPlan(estado) {
		this.editable = !this.editable;
		this.evaluationSvc.lockActionPlan({ actionPlanId: this.datos.id, estado: estado }).subscribe((data) => {
			if (data.success) {
				this.datos.editable = false;
			}
		});
	}

	classEstadoPlanAccion(estado: string, type) {
		let classEstado = "";

		if (type != 0) {
			return classEstado;
		}

		if (estado === "100") {
			classEstado = "alert-success";
		} else if (estado === "0") {
			classEstado = "alert-danger";
		} else if (estado === "50") {
			classEstado = "alert-warning";
		} else if (estado === "NA") {
			classEstado = "alert-muted";
		}

		if (this.datos.noApply === 1) {
			classEstado = "pda-disabled";
		}
		return classEstado;
	}

	classEvaluacion(estado: string) {
		let classEstado = "";

		if (estado === "100") {
			classEstado = "btn-success";
		} else if (estado === "0") {
			classEstado = "btn-danger";
		} else if (estado === "50") {
			classEstado = "btn-warning";
		} else if (estado === "NA") {
			classEstado = "btn-muted";
		}

		return classEstado;
	}

	iconEvaluacion(estado: string) {
		let iconEvaluacion = "";

		if (estado === "100") {
			iconEvaluacion = "check";
		} else if (estado === "0") {
			iconEvaluacion = "close";
		} else if (estado === "50") {
			iconEvaluacion = "access_time";
		} else if (estado === "NA") {
			iconEvaluacion = "pause";
		}

		return iconEvaluacion;
	}

	titleEvaluacion(estado: string) {
		let titleEvaluacion = "";

		if (estado === "100") {
			titleEvaluacion = "Completado";
		} else if (estado === "0") {
			titleEvaluacion = "No completado";
		} else if (estado === "50") {
			titleEvaluacion = "En progreso";
		} else if (estado === "NA") {
			titleEvaluacion = "No aplica";
		}

		return titleEvaluacion;
	}

	//Metodos nuevos para el criterio de los pda de leropy

	solicitarCancelacion() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: [],
			//width: '25vw',
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "¿Desea solicitar la deshabilitación de este plan de acción?",
				confirmMessage: "Se le enviará a su responsable esta solicitud en forma de notificación.",
				showCancelButton: true,
			},
		});

		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.evaluationSvc.solicitarDisabledPDA(this.datos.id, 1).subscribe((data) => {
					this.datos.disable_request = 1;
				});
			}
		});
	}

	revocarCancelacion() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: [],
			//width: '25vw',
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "¿Desea revocar la deshabilitación de este plan de acción?",
				confirmMessage: "Tenga en cuenta que su manager ya ha recibido una notificación para revocarlo",
				showCancelButton: true,
			},
		});

		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.evaluationSvc.solicitarDisabledPDA(this.datos.id, 0).subscribe((data) => {
					this.datos.disable_request = 0;
				});
			}
		});
	}

	revocarCancelacionManager() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: [],
			//width: '25vw',
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "¿Desea revocar la solicitud de deshabilitar este plan de acción?",
				confirmMessage: "Al realizar esta acción se cancelará la solicitud del usuario",
				showCancelButton: true,
			},
		});

		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.evaluationSvc.solicitarDisabledPDA(this.datos.id, 0).subscribe((data) => {
					this.datos.disable_request = 0;
				});
			}
		});
	}
	aceptarCancelacionManager() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: [],
			//width: '25vw',
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "¿Desea aceptar la deshabilitación de este plan de acción?",
				confirmMessage: "Al aceptar esta solicitud, el rol de este plan de acción pasará a ser testimonial ya que no aplicará a ninguna evaluación",
				showCancelButton: true,
			},
		});

		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.evaluationSvc.disablePDAnoApply(this.datos.id, 1).subscribe((data) => {
					this.datos.noApply = 1;
				});
			}
		});
	}
	reactivarPdaManager() {
		const dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
			panelClass: [],
			//width: '25vw',
			disableClose: false,
			autoFocus: false,
			data: {
				confirmTitle: "¿Desea reactivar a su colaborador este plan de acción?",
				confirmMessage: "Al realizar esta acción plan de acción volverá a estar habilitado",
				showCancelButton: true,
			},
		});

		dialogRef.beforeClosed().subscribe((response) => {
			if (response) {
				this.evaluationSvc.disablePDAnoApply(this.datos.id, 0).subscribe((data) => {
					this.datos.noApply = 0;
					this.evaluationSvc.solicitarDisabledPDA(this.datos.id, 0).subscribe((data) => {
						this.datos.disable_request = 0;
					});
				});
			}
		});
	}

	isFormInvalid() {
		const startDate = moment(this.formActionPlans.value["startDate"]);
		const endDate = moment(this.formActionPlans.value["endDate"]);

		return !startDate.isValid() || !endDate.isValid() || !this.formActionPlans.value["title"] || endDate < startDate;
	}
}
