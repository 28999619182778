import { Component, Input, OnInit } from "@angular/core";

import { CalendarEvent, CalendarView, DAYS_OF_WEEK } from "angular-calendar";
import { AdminService } from "app/services/admin/admin.service";
import moment from "moment";
@Component({
	selector: "app-angular-calendar",
	templateUrl: "./angular-calendar.component.html",
	styleUrls: ["./angular-calendar.component.scss"],
})
export class AngularCalendarComponent implements OnInit {
	view: CalendarView = CalendarView.Month;
	CalendarView = CalendarView;
	viewDate: Date = new Date();
	weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
	//events: CalendarEvent[] = [];
	pivote = 0;
	activeDayIsOpen: boolean = true;
	constructor(public adminSvs: AdminService) {}

	@Input("plantilla") plantilla: any;
	plantillaId;

	showMenuActualizar = false;

	events: CalendarEvent[] = [];
	ngOnInit(): void {
		this.plantillaId = Number(this.plantilla.id);
		this.getEventos();
	}
	setView(view: CalendarView) {
		this.view = view;
	}

	closeOpenMonthViewDay() {
		this.activeDayIsOpen = false;
	}

	getEvent(day) {
		if (day?.events?.length > 0) {
			return day.events[0].title;
		}
	}

	getEventos() {
		this.adminSvs.getPlantillaEventsMes(this.plantillaId, this.viewDate.getFullYear(), this.viewDate.getMonth() + 1).subscribe((res) => {
			let ev = [];
			for (let elem of res.eventos) {
				elem.start = new Date(elem.start);
				ev.push(elem);
			}
			this.events = ev;
		});
	}

	converMilisegundos(horas) {
		var x = parseInt(horas) * 1000;
		var tempTime = moment.duration(x);

		var res = "";

		if (tempTime.hours() > 0) {
			res += tempTime.hours() + " h ";
		}
		if (tempTime.minutes() > 0) {
			res += tempTime.minutes() + " min ";
		}
		if (tempTime.seconds() > 0) {
			res += tempTime.seconds() + " seg ";
		}

		if (res == "") {
			res = "-";
		}

		return res;
	}
	actualizarEspecifica() {
		this.showMenuActualizar = true;
	}
	recargar() {
		this.getEventos();
	}
}
