import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatListModule } from "@angular/material/list";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { MatTableModule } from "@angular/material/table";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatTooltipModule } from "@angular/material/tooltip";
import { TranslateModule } from "@ngx-translate/core";

import { PickerModule } from "@ctrl/ngx-emoji-mart";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FuseSharedModule } from "@fuse/shared.module";

import { ActionPlanComponent } from "./action-plan/action-plan.component";
import { ComentarComponent } from "./comentar/comentar.component";
import { ItemCommentComponent } from "./comentar/item-comment/item-comment.component";
import { TimeControlAmpliadoComponent } from "./time-control-ampliado/time-control-ampliado.component";
import { TimeControlIncidenciaComponent } from "./time-control-incidencia/time-control-incidencia.component";
import { TimeControlComponent } from "./time-control/time-control.component";
import { TiposEntradaComponent } from "./tipos-entrada/tipos-entrada.component";

import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatDividerModule } from "@angular/material/divider";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatStepperModule } from "@angular/material/stepper";
import { DateRangeInputComponent } from "app/admin/components/date-range-input/date-range-input.component";
import { DateFormatDirective } from "app/directives/date-format.directive";
import { NgxDaterangepickerMd } from "ngx-daterangepicker-material";
import { ImageCropperModule } from "ngx-image-cropper";
import { ActionPlanBlueComponent } from "./action-plan-blue/action-plan-blue.component";
import { TransferDialogComponent } from "./action-plan/transfer-dialog/transfer-dialog.component";
import { CompetencyComponent } from "./competency-dialog/competency.component";
import { DialogShowIncidencia } from "./dialog-show-incidencia/dialog-show-incidencia.component";
import { DialogTimeResumenComponent } from "./dialog-time-resumen/dialog-time-resumen.component";
import { EmojiPickerComponent } from "./emoji-picker/emoji-picker.component";
import { EvaluationCommentComponent } from "./evaluation-comment/evaluation-comment.component";
import { DialogCarouselComponent } from "./image-documents-carousel/dialog-carousel/dialog-carousel.component";
import { ImagenDocumentCarouselComponent } from "./image-documents-carousel/image-documents-carousel.component";
import { ImageSelectorComponent } from "./image-selector/image-selector.component";
import { ItemPostProComponent } from "./item-post-pro/item-post-pro.component";
import { KpiDataComponent } from "./kpi-data/kpi-data.component";
import { MediaDialogComponent } from "./media-dialog/media-dialog.component";
import { TandemCalendarComponent } from "./tandem-calendar/tandem-calendar.component";
import { TiposPausaComponent } from "./tipos-pausa/tipos-pausa.component";
import { VacationCalendarPersonComponent } from "./vacation-calendar-person/vacation-calendar-person.component";
import { VacationCalendarComponent } from "./vacation-calendar/vacation-calendar.component";

import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { AngularCalendarComponent } from "./angular-calendar/angular-calendar.component";
@NgModule({
	imports: [
		CommonModule,
		TranslateModule,
		MatButtonModule,
		MatIconModule,
		MatCardModule,
		MatListModule,
		MatFormFieldModule,
		MatInputModule,
		MatDialogModule,
		MatTooltipModule,
		MatSnackBarModule,
		MatToolbarModule,
		MatTableModule,
		FontAwesomeModule,
		MatDatepickerModule,
		MatStepperModule,
		MatSelectModule,
		MatSlideToggleModule,
		PickerModule,
		MatCheckboxModule,

		FuseSharedModule,
		ImageCropperModule,
		CalendarModule.forRoot({
			provide: DateAdapter,
			useFactory: adapterFactory,
		}),
		NgxDaterangepickerMd.forRoot(),
	],
	declarations: [
		AngularCalendarComponent,
		ComentarComponent,
		ItemCommentComponent,
		TimeControlComponent,
		TimeControlAmpliadoComponent,
		TimeControlIncidenciaComponent,
		TiposEntradaComponent,
		TiposPausaComponent,
		VacationCalendarComponent,
		CompetencyComponent,
		MediaDialogComponent,
		KpiDataComponent,
		ActionPlanComponent,
		EvaluationCommentComponent,
		VacationCalendarPersonComponent,
		EmojiPickerComponent,
		DateRangeInputComponent,
		DateFormatDirective,
		TransferDialogComponent,
		ImageSelectorComponent,
		TandemCalendarComponent,
		DialogCarouselComponent,
		DialogTimeResumenComponent,
		ImagenDocumentCarouselComponent,
		ItemPostProComponent,
		ActionPlanBlueComponent,
		DialogShowIncidencia,
	],
	exports: [
		ComentarComponent,
		ItemCommentComponent,
		TimeControlComponent,
		TimeControlAmpliadoComponent,
		TimeControlIncidenciaComponent,
		TiposEntradaComponent,
		TiposPausaComponent,
		VacationCalendarComponent,
		CompetencyComponent,
		KpiDataComponent,
		ActionPlanComponent,
		EvaluationCommentComponent,
		VacationCalendarPersonComponent,
		EmojiPickerComponent,
		MatDividerModule,
		MatTableModule,
		MatSlideToggleModule,
		DateFormatDirective,
		MatStepperModule,
		DateRangeInputComponent,
		NgxDaterangepickerMd,
		ImageSelectorComponent,
		TandemCalendarComponent,
		DialogCarouselComponent,
		ImagenDocumentCarouselComponent,
		ItemPostProComponent,
		ActionPlanBlueComponent,
		DialogShowIncidencia,
		AngularCalendarComponent,
	],
})
export class SharedComponentsModule {}
