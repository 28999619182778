<div class="app-vacation-calendar">
	<div fxLayout="row" class="selectMonth">
		<div fxLayout="column" class="selector">
			<button mat-flat-button (click)="changeMes(-1)" color="primary">Anterior</button>
		</div>
		<div fxLayout="column" class="selector-text align-items-center">
			<span class="texto-mes"> {{ meses[mes] }}, {{ agno }}</span>
		</div>
		<div fxLayout="column" class="selector">
			<button mat-flat-button (click)="changeMes(1)" color="primary">Siguiente</button>
		</div>
	</div>
	<div fxLayout="row">
		<table class="table align-middle mb-0 bg-white">
			<thead>
				<tr class="headerCard">
					<th class="person"></th>
					<th class="day secundary-text" *ngFor="let dia of dias">
						{{ dia }}
						<p class="diaSemana">{{ getDiaSemana(dia) }}</p>
					</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let person of persons">
					<td class="person-container">
						<div fxLayout="row" class="fila">
							<div fxLayout="column" class="imagen">
								<img [src]="getImageUser(person.username)" alt="Avatar" />
							</div>
							<div fxLayout="column" style="width: 80%; justify-content: center">
								<b class="person-name">{{ person.name }}</b>
								<b class="person-name">S {{ person.dias_restantes }} D {{ person.dias_usados }}</b>
							</div>
						</div>
					</td>
					<ng-container *ngFor="let m of person.values">
						<td>
							<div fxLayout="row" style="height: 33%; color: transparent">-</div>
							<div
								fxLayout="row"
								class="burbuja"
								style="height: 33%; color: transparent"
								[ngClass]="[
									m == -1 ? 'color-1' : '',
									m == 1 ? 'color1' : '',
									m == 2 ? 'color2' : '',
									m == 3 ? 'color3' : '',
									m == 4 ? 'color4' : '',
									m == 5 ? 'color5' : '',
									m == 6 ? 'color6' : '',
									m == 7 ? 'color7' : '',
									m == 8 ? 'color8' : '',
									m == 9 ? 'color9' : '',
									m == 10 ? 'color10' : '',
									m == 11 ? 'color11' : ''
								]"
								[matTooltip]="getTooltip(m)"
							>
								-
							</div>
							<div fxLayout="row" style="height: 33%; color: transparent">-</div>
						</td>
					</ng-container>
				</tr>
			</tbody>
		</table>
	</div>
</div>
