<!-- <div class="row text-center">
	<div class="col-md-4">
		<div class="btn-group">
			<div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
				Previous
			</div>
			<div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">Today</div>
			<div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Next</div>
		</div>
	</div>
	<div class="col-md-4"></div>
	<div class="col-md-4">
		<div class="btn-group">
			<div class="btn btn-primary" (click)="setView(CalendarView.Month)" [class.active]="view === CalendarView.Month">Month</div>
			<div class="btn btn-primary" (click)="setView(CalendarView.Week)" [class.active]="view === CalendarView.Week">Week</div>
			<div class="btn btn-primary" (click)="setView(CalendarView.Day)" [class.active]="view === CalendarView.Day">Day</div>
		</div>
	</div>
</div> -->

<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="margin-bottom: 1rem">
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">
		<button
			(click)="getEventos()"
			mat-raised-button
			color="primary"
			mwlCalendarPreviousView
			[view]="view"
			[(viewDate)]="viewDate"
			(viewDateChange)="closeOpenMonthViewDay()"
		>
			Anterior
		</button>
	</div>

	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">
		<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
			<h3 style="margin: 0px !important; padding: 0px !important; font-size: 18px">{{ viewDate | calendarDate : view + "ViewTitle" : "en" }}</h3>
		</div>
		<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center">
			<span
				style="font-size: 10px; font-style: italic; text-decoration: underline; cursor: pointer"
				mwlCalendarToday
				[(viewDate)]="viewDate"
				(click)="getEventos()"
				>Volver a hoy</span
			>
		</div>
	</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">
		<button
			(click)="getEventos()"
			mat-raised-button
			color="primary"
			mwlCalendarNextView
			[view]="view"
			[(viewDate)]="viewDate"
			(viewDateChange)="closeOpenMonthViewDay()"
		>
			Siguiente
		</button>
	</div>
</div>

<ng-template #customCellTemplate let-day="day" let-locale="locale">
	<div class="cal-cell-top">
		<!-- <span class="cal-day-badge" *ngIf="day.badgeTotal > 0">{{ day.badgeTotal }}</span> -->
		<span class="cal-day-number">{{ day.date | calendarDate : "monthViewDayNumber" : locale }}</span>
	</div>

	@if(getEvent(day) == 0){
	<span
		style="
			padding: 8px;
			border: 1px solid grey;
			border-radius: 5px;
			background-color: rgb(223, 223, 223);
			text-align: center;
			width: 75%;
			margin-left: 15%;
			margin-bottom: 8px;
		"
		><b>No laborable</b></span
	>

	} @if(getEvent(day) >0){
	<span
		style="
			padding: 8px;
			border: 1px solid rgb(0, 153, 255);
			background-color: rgb(223, 242, 255);
			border-radius: 5px;
			text-align: center;
			width: 70%;
			margin-left: 15%;
			margin-bottom: 8px;
		"
	>
		<b>{{ converMilisegundos(getEvent(day)) }}</b>
	</span>
	}
</ng-template>

<div [ngSwitch]="view" style="border: 2px solid var(--feedbalia-color-primary-500); padding: 8px; border-radius: 5px">
	<mwl-calendar-month-view
		*ngSwitchCase="'month'"
		[viewDate]="viewDate"
		[weekStartsOn]="weekStartsOn"
		[events]="events"
		[cellTemplate]="customCellTemplate"
	>
	</mwl-calendar-month-view>
	<mwl-calendar-week-view *ngSwitchCase="'week'" [viewDate]="viewDate" [weekStartsOn]="weekStartsOn" [events]="events"> </mwl-calendar-week-view>
	<mwl-calendar-day-view *ngSwitchCase="'day'" [viewDate]="viewDate" [weekStartsOn]="weekStartsOn" [events]="events"> </mwl-calendar-day-view>
</div>

<!-- @if(!showMenuActualizar){
<div class="w-100-p" fxLayout="row" fxLayoutAlign="center center" style="margin-top: 2rem">
	<button mat-raised-button color="primary" style="width: 24% !important" (click)="actualizarEspecifica()">^^^ Modificar esta plantilla ^^^</button>
</div>
}@else {
<div
	class="w-100-p"
	fxLayout="row"
	fxLayoutAlign="center center"
	style="margin-top: 2rem; border: 2px solid var(--feedbalia-color-primary-500); padding: 8px; border-radius: 5px"
>
	<div class="w-100-p" fxLayout="column" fxLayout="2 1 0" fxLayoutAlign="center center">Desde</div>
	<div class="w-100-p" fxLayout="column" fxLayout="2 1 0" fxLayoutAlign="center center">Hasta</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Lunes</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Martes</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Miercoles</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Jueves</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Viernes</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Sabado</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center">Domingo</div>
	<div class="w-100-p" fxLayout="column" fxLayout="1 1 0" fxLayoutAlign="center center"></div>
	<div class="w-100-p" fxLayout="column" fxLayout="2 1 0" fxLayoutAlign="center center">
		<button mat-raised-button color="primary" (click)="showMenuActualizar = !showMenuActualizar">Cancelar</button>
	</div>
	<div class="w-100-p" fxLayout="column" fxLayout="2 1 0" fxLayoutAlign="center center">
		<button mat-raised-button color="primary" (click)="actualizarEspecifica()">Lanzar modificación</button>
	</div>
</div>
} -->
