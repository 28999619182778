<div fxLayout="column" class="p-16 mb-12 item-post fuse-card">
	<div fxLayout="row" fxLayoutAlign="space-between center">
		<p class="post-date" [title]="momentDateFormat(post.fecha)">{{ momentDate(post.fecha) }}</p>
		<div class="tipo-post" *ngIf="!fromInside">
			<small *ngIf="post.tipo === 'POST-NEW'">{{ "home.new" | translate }}</small>
			<small *ngIf="post.tipo === 'POST-INFO'">{{ "pubs.infos" | translate }}</small>
		</div>

		<button mat-icon-button mat-dialog-close style="color: #666" *ngIf="fromInside">
			<mat-icon>close</mat-icon>
		</button>
	</div>

	<div fxLayout="row">
		<div *ngIf="post.autor" class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
			<img *ngIf="!isRankingLink" class="img-circle" [src]="getImagePerson(post.autor.username)" [title]="post.autor.username" />
			<!--<div *ngIf="isRankingLink" class="col-xs-12 col-sm-4 col-lg-3">
						<span class="article-date prettydate">it.post.creationDate</span>
							<div class="tile"> <h2>year creo</h2>
							<span class="info-text">recentNews.getPrettyMonth(it.post.creationDate) recentNews.getPrettyYear(it.post.creationDate)</span></div>

					</div>-->
		</div>
		<div class="pl-16">
			<h4 *ngIf="post.tipo === 'POST-NEW' && tit" class="m-0 post-titulo">
				<!-- Hecho asi debido a que hay algunos post que no tienen href dentro de title_prefix      [innerHtml]="post.title_prefix" -->
				<a style="cursor: pointer" (click)="openDialogVerPublicacion()" [innerHtml]="tit"></a>
			</h4>
			<h4 *ngIf="post.tipo !== 'POST-NEW'" class="m-0 post-titulo">
				<a style="cursor: pointer" (click)="openDialogVerPublicacion()">{{ post.titulo }}</a>
			</h4>
			<h5 *ngIf="post.autor && !isRankingLink" class="m-0 text-muted post-autor">
				{{ "home.badges.from" | translate }}
				<a mat-dialog-close [routerLink]="post.autor.id === user.id ? ['/perfil'] : ['/perfil/@' + post.autor.username]" style="cursor: pointer">{{
					post.autor.name
				}}</a>
			</h5>
		</div>
	</div>
	<div *ngIf="imageObject" style="margin-top: 5px">
		<image-documents-carousel [objects]="imageObject"> </image-documents-carousel>
	</div>

	<div class="post-content">
		<div class="content-wrapper" [innerHTML]="htmlContent"></div>
	</div>

	@if (canSeeComments) {
	<div fxFlex="1 1 0">
		<div *ngIf="!isRankingLink" fxLayout="row" fxLayoutAlign="space-between center">
			<p class="text-muted m-0">
				<span [matTooltip]="tooltipLikes" [matTooltipClass]="'tooltip-lines'" showDelay="500"
					>{{ post.numLikes }} {{ "addOn.comment.likes" | translate }}</span
				>
				·
				<a class="text-muted" (click)="toogleFormComment(post.id)">{{ post.numComments }} {{ "post.comments" | translate }}</a>
			</p>
			<div fxLayout="row" fxLayoutAlign="center center" class="post-actions">
				@if (canEditComments) {
				<div *ngIf="post.autor.id !== user.id">
					@if (projectId != 472) {
					<button *ngIf="!post.isLiked" mat-stroked-button color="primary" class="btn-like" (click)="toogleLikePost()">
						{{ "addOn.comment.like.btn" | translate }}
					</button>
					<button *ngIf="post.isLiked" mat-raised-button color="primary" class="btn-like active" (click)="toogleLikePost()">
						{{ "addOn.comment.like.btn" | translate }}
					</button>
					} @else {
					<button mat-stroked-button class="botonFunespana" (click)="toogleLikePost()">
						{{ post.isLiked ? "Deshacer Like" : "Like" }}
					</button>
					}
				</div>
				}

				<button mat-icon-button color="primary" class="btn-comments" color="primary" (click)="toogleFormComment(post.id)">
					<mat-icon>chat_bubble</mat-icon>
				</button>
			</div>
		</div>

		<fb-comentar *ngIf="showFormComent" [id_post]="post.id" [user]="user" [canEdit]="canEditComments"></fb-comentar>
	</div>
	}
</div>
