import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ControlHorarioService } from "app/services/control-horario/control-horario.service";
import { ImagesService } from "app/services/helper/images.service";
import { ProjectService } from "app/services/project/project.service";
import { VacacionesService } from "app/services/vacaciones/vacaciones.service";

@Component({
	selector: "app-dialog-show-incidencia",
	templateUrl: "./dialog-show-incidencia.component.html",
	styleUrls: ["./dialog-show-incidencia.component.scss"],
})
export class DialogShowIncidencia implements OnInit {
	projectConfig;
	inc: any = null;
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private chSvc: ControlHorarioService,
		private dialogRef: MatDialogRef<DialogShowIncidencia>,
		private projectSvc: ProjectService,
		private incidentSvc: VacacionesService,
		private imagesSvc: ImagesService,
	) {
		this.projectConfig = this.projectSvc.projectConfig;
	}

	ngOnInit(): void {
		if (this.data.personId && this.data.fecha) {
			this.incidentSvc.getIncidentPersonDay(this.data.personId, this.data.fecha).subscribe((data) => {
				this.inc = data.incidencia;
				// if (this.inc.fecha_ini) {
				// 	this.inc.fecha_ini = moment(new Date(this.inc.fecha_ini)).format("DD/MM/YYYY");
				// }
				// if (this.inc.fecha_fin) {
				// 	this.inc.fecha_fin = moment(new Date(this.inc.fecha_fin)).format("DD/MM/YYYY");
				// }
			});
		}
	}

	getImageUser(nickname) {
		return this.imagesSvc.getImagePerson(nickname, Number(this.projectSvc.getProjectId()));
	}
}
